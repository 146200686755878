import React from "react";
import {
    makeStyles,
    Box,
    CardContent,
} from '@material-ui/core/';
import ScenarioCard from "./ScenarioCard";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 10,
    },
}))

const CollapseScenarios = ({scenarios}) => {
    const classes = useStyles()
    return (
        <CardContent>
            {scenarios.map(scenario => (
                <Box key={scenario.id} className={classes.container}>
                    <ScenarioCard scenario={scenario}/>
                </Box>
            ))}
        </CardContent>
    )
}

export default CollapseScenarios;
