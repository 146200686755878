import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    Link,
    makeStyles,
    Container,
    Typography,
} from '@material-ui/core';
import {Notification, useDataProvider, useTranslate} from "react-admin";
import CustomButton from "../CustomButton";
import emailValidator from "email-validator"
import theme from "../../theme";
import {ThemeProvider} from '@material-ui/styles';
import FormWrapper from "./FormWrapper";
import FormInput from "./FormInput";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';
import CustomChangeLanguage from '../CustomChangeLanguage'

const useStyles = makeStyles((theme) => ({
    emailSentContainer: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
    },
    emailSent: {
        textAlign: 'center',
    },
    input: {
        zIndex: 10,
        height: 40,
    },
}));

const EmailSent = () => {
    const classes = useStyles();
    let chosenLanguage;

    const checkLanguage = () => {
        if (localStorage.getItem('favouriteLanguage')) {
            chosenLanguage = engLanguage
        } else chosenLanguage = itaLanguage;
    }

    return (
        <Container className={classes.emailSentContainer}>
            {checkLanguage()}
            <Typography variant="h1" className={classes.emailSent} color="primary">
                {chosenLanguage.forgotPassword.emailSent}
            </Typography>
        </Container>
    )
}

const ForgotPasswordForm = ({email, setEmail, submit, disabled, loading, defaultLanguage}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [language, setLanguage] = useState(defaultLanguage);
    
    const handleLanguage = () => {
        if (!localStorage.getItem('favouriteLanguage')) {
            setLanguage(engLanguage);
            localStorage.setItem('favouriteLanguage',`eng`)
        } else {
            setLanguage(itaLanguage);
            localStorage.removeItem('favouriteLanguage')
        }    
    }

    return ( 
        <FormWrapper description={language.forgotPassword.form.description} submit={submit}>
            <CustomChangeLanguage onClick={handleLanguage}> {language.switchLanguage} </CustomChangeLanguage>   
            <Box>
                <FormInput
                    required
                    value={email ? email : ''}
                    id="email"
                    type="email"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    autoFocus
                    placeholder={language.forgotPassword.form.email}
                    className={classes.input}
                />
            </Box>
            <Box className={classes.submit}>
                <CustomButton
                    disabled={disabled || loading}
                    type="submit"
                    fullWidth>
                    {language.forgotPassword.form.confirm}
                </CustomButton>
            </Box>
            <Notification/>
        </FormWrapper>
    )
}

const ForgotPassword = (props) => {
    const dataProvider = useDataProvider();
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        dataProvider.sendRecoveryLink(email).catch(() => {
        }).finally(() => {
            setLoading(false);
            setSent(true);
        });
    }

    useEffect(() => {
        if (email) {
            emailValidator.validate(email) ? setDisabled(false) : setDisabled(true);
        }
    }, [email])

    const checkLanguage = () => {
        if (localStorage.getItem('favouriteLanguage')) {
            return engLanguage;
        } return itaLanguage;
    }

    return (
        <ThemeProvider theme={theme}>
                {
                    sent ? <EmailSent/> :
                        <ForgotPasswordForm email={email} setEmail={setEmail} submit={submit} disabled={disabled}
                                        loading={loading} defaultLanguage={checkLanguage}/>
                }
        </ThemeProvider>
    );
}

export default ForgotPassword;