export const required = (message = 'Campo obbligatorio') =>
        value => value ? undefined : message;
export const samePassword = (message = 'Le password non coincidono', password) =>
    value => value === password ? undefined : message;
export const maxLength = (max, message = 'Testo troppo lungo') =>
        value => value && value.length > max ? message : undefined;
export const minLength = (min, message = 'Testo troppo corto') =>
    value => value && value.length < min ? message : undefined;
export const number = (message = 'Deve essere un numero') =>
        value => value && isNaN(Number(value)) ? message : undefined;
export const minValue = (min, message = 'Valore troppo basso') =>
        value => value && value < min ? message : undefined;
export const passwordValidator = (password, confirmPassword) => {
    return password === confirmPassword && RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(password)
}
export const emailValidator = (email, repeatEmail) => {
        return email === repeatEmail && RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(email)
}
