import {Box, IconButton, makeStyles} from "@material-ui/core";
import TextInput from "../Layout/Login/FormInput";
import SearchIcon from "@material-ui/icons/Search";
import {Notification, useDataProvider, useNotify, useRedirect, useTranslate} from "react-admin";
import React, {useEffect, useState} from "react";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'row',
        height: 40,
    },
    input: {
        width: 300
    }
}));

const SearchForm = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [subscriptionId] = useState('');
    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if(subscriptionId.length > 0) setDisabled(false)
    }, [subscriptionId])


    const submit = (e) => {
        e.preventDefault();
        dataProvider.getSmartbandConfig(value, 'email').then(
            redirect(`/config/${encodeURIComponent(value)}`)
        ).catch(e => {
            dataProvider.getSmartbandConfig(value, 'subscriptionId').then(() => {
                redirect(`/config/${value}`)
            }).catch(() => {
                notify('Non ci sono subscription associate')
            })
        })

    }

    const handleChange = (e) => {
        setValue(e.target.value)
        e.target.value.length > 0 && setDisabled(false)
    }


    return(
        <form onSubmit={submit} noValidate>
            <Box className={classes.form}>
                <TextInput
                    required
                    value={value}
                    id={value}
                    name={value}
                    onChange={handleChange}
                    autoFocus
                    placeholder={translate('smartband.form.emailOrSubscription')}
                    className={classes.input}
                />
                <IconButton
                    disabled={disabled}
                    type="submit">
                    <SearchIcon/>
                </IconButton>
            </Box>
            <Notification/>
        </form>
    )
}

export default SearchForm
