import React from "react";
import {useTranslate} from "react-admin";
import {makeStyles, Grid, Card, CardContent, Box, Typography} from '@material-ui/core/';
import smartbandIcons from "../assets/smartbandIcons";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0px 15px 15px 0px',
    },
    card: {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: 'solid',
        padding: 15,
    },
    icon: {
        height: 40,
        paddingRight: 10,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

const GeneralSettingsCard = ({smartband}) => {
    const classes = useStyles()
    const translate = useTranslate()
    const notConfigured = translate('smartband.firmware.notConfigured')

    const SettingsGridItem = ({label, icon, value}) => {
        return(
            <Grid item xs={6} sm={6} md={6} className={classes.root}>
                <Typography className={classes.label}>{label}</Typography>
                <Card elevation={3} className={classes.card}>
                    <CardContent>
                        <Box className={classes.listItem}>
                            <img src={icon} alt={icon} className={classes.icon}/>
                            <Typography color="primary" variant="h2">{value}</Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    const NotConfigured = () => {
        return(
            <Card className={classes.card}>
                <CardContent>
                    <Typography>{notConfigured}</Typography>
                </CardContent>
            </Card>
        )
    }

    const Configured = ({smartband}) => {
        return(
            <Grid container>
                <SettingsGridItem label={translate('smartband.generalSettings.luminosity')} icon={smartbandIcons.luminosity} value={`${smartband.generalSettings && smartband.generalSettings.luminosity}%`}/>
                <SettingsGridItem label={translate('smartband.generalSettings.vibration')} icon={smartbandIcons.vibration} value={`${smartband.generalSettings && smartband.generalSettings.vibration}%`}/>
            </Grid>
        )
    }
    return (
        <Box>
            {smartband ? <Configured smartband={smartband}/> : <NotConfigured/>}
        </Box>

    )
}

export default GeneralSettingsCard
