import React, {useEffect, useState} from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {useDataProvider, useNotify} from "react-admin";
import theme from "../../theme";
import {passwordValidator} from "../../utils/fieldValidator";
import RegistrationSuccess from "./RegistrationSuccess";
import {guestFormValidator, registrationFormValidator} from "../../utils/utils";
import RegistrationForm from "./RegistrationForm";
import queryString from "query-string";
import LWCircularProgress from "../LWCircularProgress";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';

const Registration = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationId, setValidationId] = useState(null);
    const [fetchingValidationId, setFetchingValidationId] = useState(true);
    const [fetchingSubscriptionId, setFetchingSubscriptionId] = useState(true);

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (password !== repeatPassword) {
            notify('registration.notifications.errors.passwordValidation', 'error')
        } else if (passwordValidator(password, repeatPassword)) {
            let body = {
                email: email,
                name: name,
                password: password,
                subscriptionId: subscriptionId,
                role: 'owner'
            }
            if(validationId) {
                body = {...body, role: 'user', validationLink: validationId}
            }
            dataProvider.register(body).then(() => {
                setDisabled(true);
                notify('registration.notifications.success', 'info');
                setRegistrationSuccess(true);
            }).catch((err) => {
                switch (err.status) {
                    case 403:
                        return notify('registration.notifications.errors.403', 'error')
                    case 409:
                        return notify('registration.notifications.errors.409', 'error')
                    default:
                        return notify('registration.notifications.errors.default', 'error', {
                            status: err.status,
                            message: err.message
                        })
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            notify('registration.notifications.errors.invalidPassword', 'error');
        }
    };

    useEffect(() => {
        if (fetchingValidationId) {
            const values = queryString.parse(props.location.search)
            if (values.validationId) {
                setValidationId(values.validationId)
            }
        }
        setFetchingValidationId(false);
    }, [props.location.search, fetchingValidationId, setFetchingValidationId])

    useEffect(() => {
        if (fetchingSubscriptionId) {
            const values = queryString.parse(props.location.search)
            if (values.subscriptionId) {
                setSubscriptionId(values.subscriptionId)
            }
        }
        setFetchingSubscriptionId(false)
    }, [props.location.search, fetchingSubscriptionId, setFetchingSubscriptionId, setSubscriptionId])

    useEffect(() => {
        setDisabled(true)

        if(validationId && validationId.length > 0) {
            if(guestFormValidator(password, repeatPassword, validationId, name)) setDisabled(false)
        }

        if(subscriptionId && subscriptionId.length > 0) {
            if (registrationFormValidator(email, repeatEmail, password, repeatPassword, subscriptionId, name)) setDisabled(false)
        }
    }, [validationId, subscriptionId, name, email, repeatEmail, password, repeatPassword]);

    const checkLanguage = () => {
        if (localStorage.getItem('favouriteLanguage')) {
            return engLanguage
        } else return itaLanguage;
    }

    return (
        <ThemeProvider theme={theme}>
            {
                loading ? <LWCircularProgress/> :
                    registrationSuccess ? <RegistrationSuccess isGuest={validationId && validationId.length > 0}/> :
                        <RegistrationForm disabled={disabled} loading={loading} submit={submit}
                                          setEmail={setEmail} email={email}
                                          setRepeatEmail={setRepeatEmail} repeatEmail={repeatEmail}
                                          setPassword={setPassword} password={password}
                                          setRepeatPassword={setRepeatPassword} repeatPassword={repeatPassword}
                                          setSubscriptionId={setSubscriptionId} setName={setName}
                                          subscriptionId={subscriptionId}
                                          validationId={validationId} fetchingValidationId={fetchingValidationId}
                                          defaultLanguage={checkLanguage}/>
            }
        </ThemeProvider>
    );
}

export default Registration;