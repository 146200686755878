import * as React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {Notification} from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
        minHeight: '100vh',
        overscrollBehavior: 'none',
    },
    gutters: {
        marginRight: 0,
    }
}));

const IntendiMeLayout = ({
                          children,
                          dashboard,
                          title,
                          theme
                      }) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <div>
                    <main>
                        <div>
                            {children}
                        </div>
                    </main>
                    <Notification/>
                </div>
            </div>
        </ThemeProvider>
    );
};

IntendiMeLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    // logout: ComponentPropType,
    title: PropTypes.string.isRequired,
};

export default IntendiMeLayout;
