import React from "react";
import {
    Box,
    Grid,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    Switch,
} from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
    daysHourContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
    }
}));

const Alarms = ({alarms}) => {
    const classes = useStyles();

    const AlarmListItem = ({alarm}) => {
        return (
            <Box>
                <Grid container>
                    <Grid item xs={8} sm={8} md={8}>
                        <Box className={classes.daysHourContainer}>
                            <Typography variant="h2" style={{color: '#444547'}}>{alarm.info.hour}</Typography>
                            <Typography>{alarm.info.name} {alarm.info.days.join()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item className={classes.statusContainer} xs={4} sm={4} md={4}>
                        <Switch
                            checked={alarm.status === 'active'}
                            name="status"
                            color="primary"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <CardContent>
            <Divider/>
            {alarms.map(alarm => (
                <Box key={alarm.id}>
                    <AlarmListItem alarm={alarm}/>
                </Box>
            ))}
        </CardContent>
    )
}

export default Alarms;
