import React, {useEffect, useState} from 'react';
import {Box, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import Message from "../Message";
import {useNotify, useDataProvider} from "react-admin";
import theme from "../../theme";
import logo from "../../assets/logo-300x95.png";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';

const defaultLanguage = () => {
    if (localStorage.getItem('favouriteLanguage')) {
        return engLanguage;
    } else return itaLanguage;
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(1, 0, 1),
    },
    input: {
        zIndex: 10,
        height: 40,
    },
    activateContainer: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

const ValidationResult = ({isValid}) => {
    const classes = useStyles();

    return (
        <Box className={classes.activateContainer}>
            <img src={logo} alt={logo}/>
            {isValid ?
                <Message title={defaultLanguage().activate.success} subtitle={defaultLanguage().activate.successSubtitle} /> :
                <Message title={defaultLanguage().activate.error} subtitle={defaultLanguage().activate.errorSubtitle} />}
        </Box>
    )
}

const Activate = (props) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [isValid, setIsValid] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!validated) {
            setLoading(true);
            const userId = props.match.params.userId;
            const validationId = props.match.params.validationId;
            dataProvider.activateAccount({userId: userId, code: validationId}).then(res => {
                setIsValid(true);
            }).catch((e) => {
                console.log(`Error ${e.status} - ${e.message}`);
            }).finally(() => {
                setLoading(false);
            })
            setValidated(true);
        }
    }, [dataProvider, validated, props.match.params, notify]);


    return (
        <ThemeProvider theme={theme}>
            {
                loading ? <CircularProgress/> : <ValidationResult isValid={isValid}/>
            }
        </ThemeProvider>
    );
}

export default Activate;