import {InputBase, withStyles} from "@material-ui/core";



const FormInput = withStyles((theme) => ({
    root: {
        height: 40,
        width: '100%',
        'label + &': {
            marginTop: theme.spacing(3),
        },
        marginBottom: 10,
        textAlign: 'center',
        alignItems: 'center',
    },
    input: {
        textAlign: 'center',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid #ced4da',
        fontSize: 14,
        color: '#225599',
        padding: '10px 12px',
        '&:focus': {
            borderColor: '#225599',
            color: '#225599',
        },
    },
}))(InputBase);

export default FormInput;
