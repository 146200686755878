import {Box, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    messageContainer: {
        padding: 20,
        textAlign: 'center',
    },
}))

const Message = ({title, subtitle}) => {
    const classes = useStyles()

    return(
        <Box className={classes.messageContainer}>
            <Typography variant="h3" color="primary" gutterBottom>{title}</Typography>
            <Typography variant="h5" color="primary">{subtitle}</Typography>
        </Box>
    )
}

export default Message
