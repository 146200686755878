import React from 'react';
import {useTranslate} from 'react-admin';
import {Box, makeStyles, Typography} from '@material-ui/core/';
import notFound from "../assets/issue.png";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    notFoundText: {
        padding: 40,
        [theme.breakpoints.only('xs')]: {
            padding: 20,
            fontSize: 32
        },
    },
    notFoundImage: {
        width: 400,
        [theme.breakpoints.only('xs')]: {
            width: 200,
        },
    }
}));

const NotFound = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Box className={classes.container}>
            <Typography color="primary" variant="h1" className={classes.notFoundText}>{translate('pageNotFoundText')}</Typography>
            <img className={classes.notFoundImage} src={notFound} alt={notFound}/>
        </Box>
    )
}

export default NotFound;
