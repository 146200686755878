import * as React from "react";
import { Route } from 'react-router-dom';
import CustomForgotPassword from "./Layout/Login/ForgotPassword";
import Registration from "./Layout/Login/Registration";
import Recovery from "./Layout/Login/Recovery";
import SmartbandList from "./smartband/SmartbandList";
import Activate from "./Layout/Login/Activate";
import SensorCard from "./smartband/SensorCard";

export default [
    <Route exact path="/forgotPassword" component={CustomForgotPassword} noLayout/>,
    <Route exact path="/user/register" component={Registration} noLayout/>,
    <Route exact path="/:userId/recover/:recoverId" component={Recovery}/>,
    <Route exact path="/:userId/activate/:validationId" component={Activate}/>,
    <Route exact path="/config/:subscriptionId" component={SmartbandList}/>,
    <Route exact path="/recovery" component={Recovery}/>
];
