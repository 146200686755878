import * as React from "react";
import {Admin, Resource, usePermissions} from 'react-admin';
import authProvider from './authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import italianMessages from './i18n/it';
import UserLayout from './Layout/UserLayout';
import CustomLogin from './Layout/Login/Login';
import dataProvider from './DataProvider';
import customRoutes from "./customRoutes";
import NotFound from "./Layout/NotFound";
import theme from "./theme";
import SmartbandList from "./smartband/SmartbandList";

const i18nProvider = polyglotI18nProvider(() => italianMessages, 'it', {allowMissing: true});

const App = () => {
    const {permissions} = usePermissions();
    return (
        <Admin
            theme={theme}
            local={'it'}
            title="IntendiMe"
            authProvider={authProvider}
            customRoutes={customRoutes}
            layout={UserLayout}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            loginPage={CustomLogin}
            catchAll={NotFound}
        >
            <Resource name="config" list={SmartbandList}/>
        </Admin>
    )
}


export default App;
