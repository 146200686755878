export const userStatus = {
    conditionAccepted: 'conditionAccepted',
    skipped: 'skipped',
    configuring: 'configuring',
    configured: 'configured'
}

export const sensorStatus = {
    initialized: 'initialized',
    configuring: 'configuring',
    configured: 'configured'
}
