import scenarios from '../assets/clockIcon.png'
import luminosity from '../assets/luminosityIcon.png'
import smartband from '../assets/smartbandIcon.png'
import vibration from '../assets/vibrationIcon.png'
import settings from '../assets/settingsIcon.png'
import sensor from '../assets/sensorIcon.png'
import alarm from '../assets/alarmIcon.png'
import user from '../assets/userIcon.png'
import firmware from '../assets/firmwareIcon.png'
import notificationStatus from '../assets/notificationStatusIcon.png'
import notificationSettings from './notificationStatusIcon.png'
import emergencyCall from './emergencyCallIcon.png'

export default {
    scenarios: scenarios,
    luminosity: luminosity,
    smartband: smartband,
    vibration: vibration,
    settings: settings,
    sensor: sensor,
    alarm: alarm,
    user: user,
    firmware: firmware,
    notificationStatus: notificationStatus,
    notificationSettings: notificationSettings,
    emergencyCall: emergencyCall,
}
