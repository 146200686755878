import screen1 from './screenAspect/screen1.png'
import screen2 from './screenAspect/screen2.png'
import screen3 from './screenAspect/screen3.png'
import screen4 from './screenAspect/screen4.png'

export default {
    aspect1: screen1,
    aspect2: screen2,
    aspect3: screen3,
    aspect4: screen4,
}
