import React from "react"
import {useTranslate} from 'react-admin'
import {CardContent, Card, Grid, makeStyles, Switch, Typography, Divider} from '@material-ui/core/'
import notificationIcons from "../assets/notificationIcons"
import screenAspects from "../assets/screenAspects"
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 40,
    },
    card: {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 15,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        color: '#17324D',
    },
    notificationTypeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        color: '#17324D',
    },
    notificationColorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    notificationColor: {
        textAlign: 'right',
    },
    notificationTypeIcon: {
        width: 25,
        height: 25,
        paddingRight: 12,
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '1005'
        // alignItems: 'flex-end',
    },
    aspectsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    aspect: {
        width: 100,
        padding: 5,
    },
    selectedAspect: {
        borderStyle: 'solid',
        borderWidth: 10,
        borderColor: theme.palette.secondary.main,
    }
}));


const NotificationSettings = ({notificationSettings}) => {
    const translate = useTranslate()
    const classes = useStyles()

    const getNotificationIcon = type => {
        switch (type) {
            case 'sensor':
                return notificationIcons.sensor
            case 'alarm':
                return notificationIcons.alarm
            case 'smartband':
                return notificationIcons.smartband
            default:
                return notificationIcons.sensor
        }
    }

    const Aspect = ({screen, selectedAspect, screenAspect}) => {
        return(
            <img className={clsx(classes.aspect, selectedAspect===screenAspect && classes.selectedAspect)} src={screen} alt={screen}/>
        )
    }

    const SmartbandAspects = ({selectedAspect}) => {
        return(
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Aspect screen={screenAspects.aspect1} selectedAspect={selectedAspect} screenAspect='screen1'/>
                    <Aspect screen={screenAspects.aspect2} selectedAspect={selectedAspect} screenAspect='screen2'/>
                    <Aspect screen={screenAspects.aspect3} selectedAspect={selectedAspect} screenAspect='screen3'/>
                    <Aspect screen={screenAspects.aspect4} selectedAspect={selectedAspect} screenAspect='screen4'/>
                </Grid>
            </Grid>
        )
    }

    const ItemSwitch = ({customizedColors}) => {
        return (
            <Grid item className={classes.statusContainer} xs={4} sm={4} md={4}>
                <Switch
                    checked={customizedColors}
                    name="status"
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                    disableRipple={true}
                    disabled={true}
                />
            </Grid>
        )
    }

    const IconLabel = ({notification}) => (
        <Grid item className={classes.notificationTypeContainer} xs={8} sm={8} md={8}>
            <img className={classes.notificationTypeIcon} src={getNotificationIcon(notification.type)}
                 alt={notification.type}/>
            <Typography>{notification.label}</Typography>
        </Grid>
    )


    const NotificationsListItem = ({label, color, customizedColors, leftItem, rightItem}) => {
        return (
            <Grid container className={classes.container}>
                {leftItem ? leftItem :
                    <Grid item xs={8} sm={8} md={8} style={{opacity: `${customizedColors && '0.5'}`}}>
                        <Typography>{label}</Typography>
                    </Grid>}
                <Grid item className={classes.notificationColorContainer} xs={4} sm={4} md={4}>
                    {rightItem ?
                        rightItem :
                        <Typography className={classes.notificationColor}
                                    style={{color: `#${color}`}}>⬤</Typography>
                    }
                </Grid>
            </Grid>
        )
    }

    return (
        <Card elevation={4} className={classes.card}>
            {notificationSettings &&
            <CardContent>
                <NotificationsListItem label={translate('smartband.notificationSettings.notificationEnabled')}
                                       rightItem={<ItemSwitch
                                           customizedColors={notificationSettings.notificationEnabled}/>}/>
                <NotificationsListItem label={translate('smartband.notificationSettings.uniqueColor')}
                                       color={notificationSettings.uniqueColor}
                                       customizedColors={notificationSettings.customizedColors}/>
                <NotificationsListItem label={translate('smartband.notificationSettings.customizedColors')}
                                       rightItem={<ItemSwitch
                                           customizedColors={notificationSettings.customizedColors}/>}/>
                {notificationSettings.notifications &&
                notificationSettings.notifications.map(notification =>
                    <NotificationsListItem key={notification.id} leftItem={<IconLabel notification={notification}/>}
                                           color={notification.color}/>)}
                <Divider className={classes.divider}/>
                <SmartbandAspects selectedAspect={notificationSettings.selectedScreen}/>
            </CardContent>
            }
        </Card>
    )
}

export default NotificationSettings;
