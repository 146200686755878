import React from "react";
import {makeStyles, Box, CardActions, Collapse, Typography, IconButton} from '@material-ui/core/';
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    icon: {
        height: 30,
    },
    collapsedText: {
        paddingLeft: 10,
        fontWeight: 'bold',
    },
    iconTitle: {
        marginBottom: 10,
        height: 30,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
    }
}))

const CollapsableListItem = ({icon, label, count, content, warning}) => {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box>
            <CardActions disableSpacing>
                <img src={icon} alt="scenary" className={classes.icon}/>
                <Typography color="primary" className={classes.collapsedText}>{label}{count && ` (${count})`}</Typography>
                {warning ? <ErrorIcon color="primary"/> : null}
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    disabled={parseInt(count) === 0}
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {content}
            </Collapse>
        </Box>
    )
}

export default CollapsableListItem;
