import React from 'react';
import {useTranslate} from "react-admin";
import {Container, Typography, makeStyles} from '@material-ui/core';
import logo from "../../assets/logo-300x95.png";
import Message from "../Message";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    },
    header: {
        textAlign: 'center',
    }
}));

const RegistrationSuccess = ({isGuest}) => {
    const translate = useTranslate();
    const classes = useStyles();
    let chosenLanguage;

    const checkLanguage = () => {
        if (localStorage.getItem('favouriteLanguage')) {
            chosenLanguage = engLanguage.registration
        } else chosenLanguage = itaLanguage.registration;
    }

    return (
        <Container className={classes.root}>
            <img src={logo} alt={logo}/>
            {checkLanguage()}
            {isGuest && <Message title={chosenLanguage.RegistrationSuccess} subtitle={chosenLanguage.guestRegistrationSuccessNote} />}
            {!isGuest && <Message title={chosenLanguage.RegistrationSuccess} subtitle={chosenLanguage.registrationSuccessNote} />}
        </Container>
    )
}

export default RegistrationSuccess;