import {Box, Typography} from "@material-ui/core";
import logo from "../../assets/logo-300x95.png";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        alignContent: 'center',
        maxWidth: 315,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    formDescription: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 20,
    },
}));

const FormWrapper = ({...props}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.paper}>
                <img src={logo} alt={logo}/>
                <Typography className={classes.formDescription} color="primary">
                    {props.description}
                </Typography>
                <form className={classes.form} onSubmit={props.submit} noValidate>
                    {props.children}
                </form>
            </Box>
        </Box>
    );
}

export default FormWrapper;
