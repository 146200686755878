import React from "react";
import {
    makeStyles,
    Box,
    CardContent,
} from '@material-ui/core/';
import SensorCard from "./SensorCard";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 10,
    },
}))

const CollapseSensors = ({sensors}) => {
    const classes = useStyles()
    return (
        <CardContent>
            {sensors.map(sensor => (
                <Box key={sensor.id} className={classes.container}>
                    <SensorCard sensor={sensor}/>
                </Box>
            ))}
        </CardContent>
    )
}

export default CollapseSensors;
