import {TranslationMessages} from 'ra-core';
import italianMessages from 'ra-language-italian';

const customItalianMessages: TranslationMessages = {
    ...italianMessages,
    notifications: {
        genericError: 'Errore durante il login',
        unauthorizedUser: 'Utente non autorizzato',
    },
    pageNotFoundText: 'Pagina non trovata',
    login: {
        form: {
            description: 'Benvenuto! Inserisci email e password per accedere.',
            emailInput: 'Email',
            passwordInput: 'Password',
            loginButtonLabel: 'Accedi',
            rememberMe: 'Ricordami',
            register: 'Registrazione',
            forgotPassword: 'Hai dimenticato la password?'
        }
    },
    forgotPassword: {
        emailSent: 'Perfetto! Se la tua mail è presente nel sistema riceverai un link con le istruzioni di recupero',
        resetFailed: 'Qualcosa è andato storto, riprova o scrivici a servizioclienti@intendime.com',
        resetSuccess: 'Mail recupero inviata',
        form: {
            title: 'Recupero password',
            description: 'Inserisci la tua email per recuperare la password.',
            email: 'Email',
            confirm: 'Invia',
            back: 'Indietro',
        }
    },
    recovery: {
        invalidRecoveryLink: 'Siamo spiacenti, il link non è più valido',
        resetFailed: 'Non è stato possibile impostare la password, riprova o scrivici a servizioclienti@intendime.com',
        resetSuccess: 'Password impostata correttamente, ora torna nell\'app e loggati',
        form: {
            title: 'Nuova Password',
            description: 'Imposta la tua nuova password',
            password: 'Password',
            repeatPassword: 'Ripeti Password',
            confirm: 'Conferma',
            invalidPassword: 'La password deve essere di almeno 8 caratteri, con maiuscole, minuscole e almeno un simbolo',
        }
    },
    registration: {
        registrationSuccess: 'Complimenti, la registrazione è andata a buon fine',
        registrationSuccessNote: 'A breve riceverai una mail con un link per attivare il tuo account',
        guestRegistrationSuccessNote: 'Adesso puoi loggarti nell\'applicazione con le credenziali che hai creato',
        registrationSuccessSecondNote: 'Cliccando sul link il tuo account sarà attivo su IntendiMe, potrai accedere all\'app tramite  l\'indirizzo  email e la password che hai scelto nella schermata precedente e configurare i tuoi smartwatch e i tuoi sensori',
        form: {
            title: 'Nuova Password',
            description: 'Inserisci i dati di registrazione',
            email: 'Email',
            repeatEmail: 'Ripeti Email',
            password: 'Password',
            repeatPassword: 'Ripeti Password',
            displayName: 'Nome',
            subscriptionId: 'Codice abbonamento',
            register: 'Registra',
            passwordsNotMatching: 'le password non coincidono',
            emailNotMatching: 'le email non coincidono',
            passwordNotValid: 'la password deve avere almeno 8 caratteri, maiuscole, minuscole e numeri',
            invalidEmail: 'inserisci un formato email valido'
        },
        notifications: {
            success: 'Account creato',
            errors: {
                default: 'Errore %{status} - %{message}',
                passwordValidation: 'Le password non coincidono',
                invalidPassword: 'La password deve avere almeno 8 caratteri, con maiuscole, minuscole e numeri',
                403: 'Errore, l\'ID di questo abbonamento non è valido',
                409: 'Errore, questo abbonamento risulta già registrato',
            }
        }
    },
    activate: {
        success: 'Il tuo account è stato attivato',
        successSubtitle: 'Entra nell\'app IntendiMe e Accedi con l\'indirizzo email e la password che hai scelto in fase di registrazione per iniziare a usare i servizi di IntendiMe',
        error: 'Il tuo account risulta già attivo',
        errorSubtitle: 'Accedi all\'app IntendiMe usando l\'indirizzo email e la password che hai scelto per iniziare a usare i servizi di IntendiMe',
    },
    subscription: {
        placeholder: 'Utilizza il form di ricerca per visualizzare le configurazioni dell\'abbonamento',
        noSubscription: 'La subscription non esiste',
        card: {
            title: 'Dettagli Abbonamento',
            subscriptionId: 'ID: ',
            multicastAddress: 'Multicast: ',
            smartbandCount: 'Smartband: ',
            sensorCount: 'Sensori configurati: ',
            expire: 'Valido fino al: ',
        },
        users: {
            success: 'Utente eliminato',
            errors: {
                default: 'Errore generico',
                404: 'Operazione fallita, utente non trovato'
            },
            noUsers: 'La smartband deve ancora essere inizializzata',
            button: 'Elimina',
            confirm: {
                title: 'Attenzione',
                content: 'L\'operazione è irreversibile, sei sicuro di voler procedere?',
            },
            card: {
                title: 'Utenti dell\'abbonamento',
                owner: 'Intestatario: ',
                member: 'Membro: ',
            }
        },
    },
    smartband: {
        noSmartbandData: 'Non ci sono smartband associate a questo abbonamento',
        notConfigured: 'Non configurato',
        na: 'n/a',
        uuid: 'UUID: %{uuid}',
        status: 'Stato: ',
        internalId: 'ID Interno: ',
        watchId: 'ID smartband: %{watchId}',
        main: 'principale',
        sensors: 'Sensori',
        alarms: 'Sveglie',
        generalSettings: {
            notifications: 'Stato notifiche',
            vibration: 'Vibrazione',
            luminosity: 'Luminosità LED',
        },
        emergencyCall: {
            self: 'Chiamate di emergenza',
            notConfigured: 'Le chiamate di emergenza non sono ancora state configurate',
            phone: 'Telefono di emergenza %{number}: ',
            type: 'Metodo di contatto preferito: ',
            text: 'Testo SMS: ',
            source: 'Telefono Utente: ',
        },
        firmware: {
            self: 'Firmware',
            notConfigured: 'Questa smartband non è ancora stata configurata',
            currentVersion: 'Versione Corrente: ',
            serverVersion: 'Versione Server: ',
            remoteUrl: 'Url: ',
            status: 'Stato: ',
        },
        user: {
            notConfigured: 'Questa smartband non è ancora stata associata a un utente',
            self: 'Utente',
            name: 'Nome: ',
            userId: 'ID: ',
            email: 'Email: ',
            status: 'Stato: ',
            termsAndCondition: 'T&C: ',
            't&c': {
                accepted: 'Accettati',
                declined: 'Non accettati',
            },
            avatar: 'Avatar: ',
            supportLanguage: 'Lingua Supporto: ',
        },
        notificationSettings: {
            self: 'Impostazioni Smartwatch',
            uniqueColor: 'Colore Unico',
            customizedColors: 'Colori Personalizzati',
            notificationEnabled: 'Abilita LED di notifiche',
            selectedScreen: 'Schermo selezionato: ',
        },
        alarm: {
            name: 'Etichetta: ',
            hour: 'Ora: ',
            status: 'Stato: ',
            days: 'Ripetizioni: ',
        },
        scenarios: {
            self: 'Scenari',
            name: 'Nome: ',
            status: 'Stato: ',
            card: {
                on: 'Tutte le notifiche attive',
                off: 'Tutte le notifiche disattivate'
            }
        },
        contacts: {
            self: 'Contatti',
            tooltips: {
                noConnection: 'Problemi di connessione'
            }
        },
        sensor: {
            statusType: {
                default: 'n/a',
                initialized: 'Questo sensore è ancora da associare',
                bound: 'Questo sensore è stato associato ma non ancora configurato',
                configured: 'Questo sensore è stato configurato'
            },
            errors: {
                default: 'Errore generico',
                404: 'Operazione fallita, sensore non trovato'
            },
            confirm: {
                title: 'Attenzione',
                content: 'Stai per rimuovere il sensore, sei sicuro di voler procedere?',
                success: 'Sensore eliminato',
            },
            uuid: 'UUID: ',
            sensorIndex: 'Indice Sensore: ',
            status: 'Stato: ',
            configuration: {
                name: 'Nome: ',
                icon: 'Icona: ',
                notificationType: 'Tipo notifiche: ',
                usageType: 'Tipo utilizzo: ',
                sensitivity: 'Sensibilità: ',
                soundSpring: 'Fonte sonora: ',
                soundSource: {
                    type0: 'Altro',
                    type1: 'Citofono',
                    type3: 'Porta o Finestra',
                    type4: 'Telefono',
                    type5: 'Allarme',
                    type6: 'Robot da cucina',
                    type7: 'Campanello',
                    type8: 'Asciugatrice',
                    type9: 'Lavatrice',
                    type10: 'BabyMonitor',
                    type11: 'Culla',
                    type12: 'Forno',
                    type13: 'Lavastoviglie',
                    type14: 'Microonde',
                    type15: 'Configurazione avanzata',
                },
                sensitivityType: {
                    label:{
                        ultralow: 'ultra basso',
                        verylow: 'molto basso',
                        low: 'basso',
                        mediumlow: 'medio basso',
                        medium: 'medio',
                        mediumhigh: 'medio alto',
                        high: 'alto',
                        veryhigh: 'molto alto',
                        ultrahigh1: 'super alta 1',
                        ultrahigh2: 'super alta 2',
                        sound: 'suono'
                        }
                }
            },
            firmware: {
                currentVersion: 'Vers. : ',
                serverVersion: 'Server V.: ',
                remoteUrl: 'Url: ',
                status: 'Stato: ',
            },
        },
        form: {
            emailOrSubscription: 'Cerca per email o Codice abbonamento',
            description: 'Inserisci l\'identificativo dell\'abbonamento',
            searchText: 'oppure',
        },
        errors: {
            default: 'Errore generico',
            404: 'Operazione fallita, smartband non trovata'
        },
        confirm: {
            title: 'Attenzione',
            content: 'Stai per rimuovere la smartband, sei sicuro di voler procedere?',
            success: 'Smartband eliminata',
        },
    },
    notFound: {
        text: {
            first: 'Assembramento',
            second: 'nel server',
        },
        button: {
            retry: 'Riprova',
        }
    },
};

export default customItalianMessages;
