import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Confirm, useDataProvider, useNotify, useRefresh } from "react-admin";
import React, { useState } from "react";

const DeleteSmartwatchButton = ({ smartwatch }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const mainSmartwatch = smartwatch.main;

  const tooltipMsg = () => {
    if (mainSmartwatch) return "Resetta smartwatch primario";
    else if (smartwatch.subscriptionId === smartwatch.user.subscriptionId)
      return "Elimina smartband dalla subscription";
    else return "Elimina guest smartband dalla subscription";
  };

  const handleDeleteClick = () => setOpen(true);
  const handleDeleteDialogClose = () => setOpen(false);

  const handleUpdateConfirm = (smartwatch) => {
    dataProvider
      .update("backoffice/smartwatch/unlink", { id: smartwatch.id })
      .then(() => {
        notify("smartband.confirm.success");
        window.location.reload(true);
      })
      .catch((e) => {
        switch (e.status) {
          case 404:
            return notify("smartband.errors.404", "error");
          default:
            notify("smartband.errors.default", "error", {
              status: e.status,
              message: e.message,
            });
        }
      });
    setOpen(false);
  };

  return (
    <React.Fragment>
      {localStorage
        .getItem("email")
        .startsWith("admin") && smartwatch.user && (
          <Tooltip title={tooltipMsg()}>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      {console.log(smartwatch)}
      <Confirm
        isOpen={open}
        loading={loading}
        title="smartband.confirm.title"
        content="smartband.confirm.content"
        onConfirm={() => handleUpdateConfirm(smartwatch)}
        onClose={handleDeleteDialogClose}
      />
    </React.Fragment>
  );
};

export default DeleteSmartwatchButton;