import {makeStyles, Card, CardContent, Typography, Box, Grid, Divider, Chip} from '@material-ui/core/';
import React, {useState} from "react";
import {useDataProvider, useTranslate, useRefresh, useNotify} from "react-admin";
import SimpleListItem from "./SimpleListItem";
import DeleteUserButton from "../Layout/DeleteUserButton";
import ActivateUserButton from "../Layout/ActivateUserButton";

const useStyles = makeStyles((theme) => ({
    root: {
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderSize: 2,
        padding: 15,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '700px',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    noUsers: {
        padding: 10
    },
}));

const UsersCard = ({subscription}) => {
    const classes = useStyles();
    const translate = useTranslate();

    let users = subscription.users

    return (
        <Card className={classes.root}>
            <Typography color="primary" variant="h1">{translate('subscription.users.card.title')}</Typography>
            <Divider/>
            <Grid container className={classes.gridContainer} spacing={1}>
                <Grid item key="smartband1" xs={12} sm={6} md={6}>
                    <CardContent>
                        {users && users.length > 0 ? users.map(user => (
                                <Box className={classes.container} key={user.email}>
                                    <SimpleListItem
                                        itemLabel={user.role === 'owner' ? translate('subscription.users.card.owner') : translate('subscription.users.card.member')}
                                        itemValue={user.email}/>
                                    <DeleteUserButton user={user}/>
                                    {
                                        user.status === 'pending' && <ActivateUserButton user={user}/>
                                    }
                                    <Chip className={classes.chip} variant="outlined" label={user.status}/>
                                </Box>
                            )
                        ) : <Typography
                            className={classes.noUsers}>{translate('subscription.users.noUsers')}</Typography>}
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export default UsersCard
