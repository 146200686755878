import {IconButton, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {Confirm, useDataProvider, useNotify, useRefresh, useTranslate} from "react-admin";
import {Box} from "../smartband/UsersCard";
import React, {useState} from "react";



const DeleteSensorButton = ({sensor}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const refresh = useRefresh()
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteClick = () => setOpen(true);
    const handleDeleteDialogClose = () => setOpen(false);

    const handleUpdateConfirm = (configuration) => {
        dataProvider.update('backoffice/sensor/unlink', {id: configuration.id, data:configuration}).then(() => {
            notify('smartband.sensor.confirm.success')
            window.location.reload(true);
        }).catch((e) => {
            switch(e.status) {
                case 404: return notify('smartband.sensor.errors.404', 'error')
                default: notify('smartband.sensor.errors.default', 'error',{status: e.status , message: e.message})
            }
        })
        setOpen(false);
    };

    return(
        <React.Fragment>
            {localStorage.getItem('email').startsWith("admin") &&
            <Tooltip title="Elimina sensore dalla subscription">
                <IconButton onClick={handleDeleteClick}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            }
            <Confirm
                isOpen={open}
                loading={loading}
                title="smartband.sensor.confirm.title"
                content="smartband.sensor.confirm.content"
                onConfirm={() => handleUpdateConfirm(sensor)}
                onClose={handleDeleteDialogClose}
            />
        </React.Fragment>
    )
}

export default DeleteSensorButton;
