import React from 'react';
import {useLogout} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar} from '@material-ui/core/';
import logo from '../assets/topbar_logo.png';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SearchForm from "../smartband/SearchForm";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'sticky',
        width: '100%',
        top: 0,
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        zIndex: 1000,
    },
    toolbar: {
        minHeight: 48,
        justifyContent: 'space-between'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: '18px',
        paddingLeft: 10,
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '16px',
        },
    },
    logo: {
        left: 10,
        height: 32,
        [theme.breakpoints.down('sm')]: {
            height: 22,
        },
    },
    logout: {
        // display: 'flex',
        // position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        // alignItems: 'center',
        // textAlign: 'center',
        // justifyContent: 'center',
        right: 0,
        height: 36,
        width: 50,
        // padding: '5px 5px 5px 5px',
        cursor: 'pointer',
    }
}));

export default function CustomTopbar() {
    const classes = useStyles();
    const logout = useLogout();

    const handleClick = () => logout();

    return (
        <AppBar position="sticky" className={classes.root} elevation={1}>
            <Toolbar className={classes.toolbar}>
                <img className={classes.logo} src={logo} alt={logo}/>
                <SearchForm className={classes.search}/>
                <MeetingRoomIcon className={classes.logout} alt="logout" onClick={handleClick}/>
            </Toolbar>
        </AppBar>
    );
}
