import React, {useState, useEffect} from "react";
import {Box, makeStyles, Typography, CircularProgress} from "@material-ui/core";
import FormInput from "./FormInput";
import CustomButton from "../CustomButton";
import {Notification} from "react-admin";
import FormWrapper from "./FormWrapper";
import emailValidator from "email-validator";
import {passwordValidator} from "../../utils/fieldValidator";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';
import CustomChangeLanguage from '../CustomChangeLanguage'

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(1, 0, 1),
    },
    input: {
        zIndex: 10,
    },
    invalidInput: {
        border: '1px solid #FE6B8B',
        borderRadius: 4,
        zIndex: 10,
    },
    validatorText: {
        fontSize: 13,
        color: 'red',
        marginBottom: 10,
        marginLeft: 5,
        fontStyle: 'italic'
    }
}));

const RegistrationForm = ({loading, password, repeatPassword, disabled, submit, email, setEmail, repeatEmail, setRepeatEmail, setPassword, setRepeatPassword, setSubscriptionId, setName, validationId, fetchingValidationId, subscriptionId, defaultLanguage}) => {
    const classes = useStyles();
    const [language, setLanguage] = useState(defaultLanguage);
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [invalidPasswordError, setInvalidPasswordError] = useState('')
    const [invalidRepeatEmail, setInvalidRepeatEmail] = useState('')

    const handleLanguage = () => {
        if (!localStorage.getItem('favouriteLanguage')){
            setLanguage(engLanguage);
            localStorage.setItem('favouriteLanguage','eng')
        } else {
            setLanguage(itaLanguage);
            localStorage.removeItem('favouriteLanguage')
        }
    }

    useEffect(() => {
        if (email.length > 0) {
            setInvalidEmail(!emailValidator.validate(email))
        }
        if (repeatEmail.length > 0 && repeatEmail == email){
            setInvalidRepeatEmail(!emailValidator.validate(repeatEmail))
        }
        else if(repeatEmail.length > 0 && repeatEmail !== email){
            setInvalidRepeatEmail(`${language.registration.form.emailNotMatching}`)
        }
    }, [language, email, repeatEmail, setInvalidEmail, setInvalidRepeatEmail])

    useEffect(() => {
        if (password.length > 0 && repeatPassword.length > 0) {
            if(password !== repeatPassword) {
                setInvalidPasswordError(`${language.registration.form.passwordsNotMatching}`)
            } else if(!passwordValidator(password, repeatPassword)) {
                setInvalidPasswordError(`${language.registration.form.passwordNotValid}`)
            }
            setInvalidPassword(!passwordValidator(password, repeatPassword))
        }
    }, [language, password, repeatPassword, setInvalidPassword])

    return (
        <>
            <FormWrapper description={language.registration.form.description} submit={submit}>
                <CustomChangeLanguage onClick={handleLanguage}> {language.switchLanguage} </CustomChangeLanguage> 
                {fetchingValidationId ? <CircularProgress/> :
                    <Box>
                        {validationId ? null :
                            <FormInput
                                required
                                value={email ? email : ""}
                                id="email"
                                name="email"
                                type="email"
                                className={invalidEmail ? classes.invalidInput : ''}
                                onChange={e => setEmail(e.target.value.toLowerCase())}
                                autoComplete="off"
                                autoFocus
                                placeholder={language.registration.form.email}
                            />}
                        {
                            invalidEmail && <Typography className={classes.validatorText}>{language.registration.form.invalidEmail}</Typography>
                        }
                        {validationId ? null :
                            <FormInput
                            required
                            id="repeatEmail"
                            type="email"
                            name="repeatEmail"
                            className={invalidRepeatEmail ? classes.invalidInput : ''}
                            onChange={e => setRepeatEmail(e.target.value)}
                            autoComplete="off"
                            placeholder={language.registration.form.repeatEmail}
                            />}
                        {
                            invalidRepeatEmail && <Typography className={classes.validatorText}>{invalidRepeatEmail}</Typography>
                        }
                        <FormInput
                        required
                        id="password"
                        type="password"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="new-password"
                        className={invalidPassword ? classes.invalidInput : ''}
                        placeholder={language.registration.form.password}
                    />
                    <FormInput
                        required
                        name="newPassword"
                        placeholder={language.registration.form.repeatPassword}
                        type="password"
                        id="newPassword"
                        className={invalidPassword ? classes.invalidInput : ''}
                        onChange={e => setRepeatPassword(e.target.value)}
                    />
                    {
                        invalidPassword && <Typography className={classes.validatorText}>{invalidPasswordError}</Typography>
                    }
                    <FormInput
                        required
                        name="displayName"
                        placeholder={language.registration.form.displayName}
                        type="name"
                        id="displayName"
                        onChange={e => setName(e.target.value)}
                    />
                    {
                        validationId ? null :
                            <FormInput
                                required
                                name="subscriptionId"
                                placeholder={language.registration.form.subscriptionId}
                                type="subscriptionId"
                                id="subscriptionId"
                                value={subscriptionId && subscriptionId}
                                onChange={e => setSubscriptionId(e.target.value)}
                            />
                    }
                </Box>
            }
            <Box className={classes.submit}>
                <CustomButton
                    disabled={disabled || loading}
                    type="submit"
                    fullWidth
                >
                    {language.registration.form.register}
                </CustomButton>
            </Box>
            <Notification/>
        </FormWrapper>
        </>
    )
}

export default RegistrationForm;