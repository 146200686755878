import moment from "moment"
import emailValidator from "email-validator"
import {userStatus} from "./status";

export const getConfiguredSensors = subscription => {
    if(!subscription) return '0';
        let sensors = [];
        if(subscription.smartwatches && subscription.smartwatches[0]){
        sensors = subscription.smartwatches[0].sensors
        }
        const configuredSensors = sensors.filter(sensor => sensor.status !== 'initialized' ? sensor : null).length
        const totalSensors = sensors.length
        return `${configuredSensors}\\${totalSensors}`;
        
   
}

export const isPublicUrl = (hash) => {
    const regex = RegExp(`#/[a-zA-Z0-9_]*/recover/[a-zA-Z0-9_]*`);
    const activateEx = RegExp(`#/[a-zA-Z0-9_]*/activate/[a-zA-Z0-9_]*`);
    const forgotPasswordEx = RegExp(`#/forgot`);
    const recoverPasswordEx = RegExp(`#/recovery`);
    const registrationEx = RegExp(`#/user/register`);
    if(forgotPasswordEx.test(hash) || registrationEx.test(hash) || recoverPasswordEx.test(hash) || activateEx.test(hash)) {
        return true
    }
    return regex.test(hash);
}

export const getUser = () => {
    const daysRemaining = moment().diff(moment(localStorage.getItem('createdAt')),'days');
    const mid = localStorage.getItem('mid');
    const email = localStorage.getItem('email');
    return {daysRemaining: daysRemaining, mid: mid, email: email};
}

export const registrationFormValidator = (email, repeatEmail, password, repeatPassword, subscriptionId, name) => {
    return emailValidator.validate(email) && (email === repeatEmail) && password.length > 0 && (password === repeatPassword) && subscriptionId.length > 0 && name.length > 0
}

export const guestFormValidator = (password, repeatPassword, validationId, name) => {
    console.log((password === repeatPassword) && validationId.length > 0 && name.length > 0)
    return password.length > 0 && (password === repeatPassword) && validationId.length > 0 && name.length > 0
}



export const getItemValue = (value) => {
    switch(value) {
        case userStatus.conditionAccepted: return '(l\'utente ha accettato le condizioni del servizio e non ha iniziato l\'associazione di smartwatch e sensori)';
        case userStatus.skipped: return '(l\'utente ha skippato la configurazione di sensori e smartwatch dopo averla iniziata)';
        case userStatus.configuring: return '(l\'utente ha iniziato la configurazione di smartwatch e sensori ma non l\'ha completata)';
        case userStatus.configured: return '(l\'utente ha configurato tutti i sensori e smartwatch)';
        default: return '';
    }
}
