import {fetchUtils} from 'react-admin';
import {stringify} from 'query-string';
import apiUrl from './utils/api.js';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('accessToken');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Content-Type', 'application/json');
    return fetchUtils.fetchJson(url, options);
};

export default {
    getList: (resource, params) => {
        let pagination = '';
        if (params && params.pagination) {
            const {page, perPage} = params.pagination;
            pagination = `&options[pagination]=true&options[page]=${page}&options[limit]=${perPage}`;
        }
        const url = `${apiUrl}/${resource}/`;
        let filters = '';
        if (params) {
            filters += params.filter && params.filter.email ? `&filters[email]=${params.filter.email}` : ''
            filters += params.filter && params.filter.role ? `&filters[role]=${params.filter.role}` : ''
        }

        return httpClient(`${url}?${filters}${pagination}`).then(({headers, json}) => ({
            data: resource === 'course' ? json.items : resource === 'reservation' ? json.items : resource === 'user' ? json.users : json,
            total: json.pagination ? json.pagination.totalDocs : 12
        }));
    },

    getOne: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        switch (resource) {
            case 'user': {
                return httpClient(`${url}/${params.id}`).then(({json}) => ({
                    data: json,
                }));
            }
            case 'shop': {
                return httpClient(`${url}/list`).then(({headers, json}) => ({
                    data: json.shops.filter(function (item) {
                        if (item.id === params.id) return item.id;
                        return {};
                    })
                }))
                // return httpClient(`${url}/${params.id}`).then(({headers, json}) => ({
                //     data: json,
                // }));
            }
            case 'voucher': {
                return httpClient(url).then(({headers, json}) => ({
                    data: json.voucher,
                }));
            }
            default :
                return;
        }

    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },

    getManyReference: (resource, params) => {
        const url = `${apiUrl}/${resource}/list/${params.id}`;
        return httpClient(url).then(({headers, json}) => ({
            data: json,
            total: json.length,
        }));
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    create: (resource, params) => {
        let body = resource === 'user' ? [params.data] : params.data;
        // if(resource === 'user') {body[0].email = body[0].email.toLowerCase()};
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(body),
        }).then(({json, status}) => ({
                data: {...params.data, id: json.id},
            })
        ).catch(error => {
            return Promise.reject(error);
        });
    },

    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json}))
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    renewPassword: (userId) => {
        return httpClient(`${apiUrl}/user/${userId}/renewPassword`, {
            method: 'PUT',
        }).then(({json}) => ({data: json}));
    },

    sendRecoveryLink: (userCode) => {
        return httpClient(`${apiUrl}/user/${userCode}/sendRecoveryLink`, {
            method: 'GET',
        }).then(({json}) => ({data: json}));
    },

    validateRecoveryLink: (body) => {
        return httpClient(`${apiUrl}/validateRecoveryLink`, {
            method: 'PUT',
            body: JSON.stringify(body),
        }).then(({json}) => ({data: json}));
    },

    activateAccount: (body) => {
        return httpClient(`${apiUrl}/user/activate`, {
            method: 'POST',
            body: JSON.stringify(body),
        }).then(({json}) => ({data: json}));
    },

    resetPassword: (body) => {
        return httpClient(`${apiUrl}/user/resetPassword`, {
            method: 'PUT',
            body: JSON.stringify(body),
        }).then(({json}) => ({data: json}));
    },

    getSmartbandConfig: (subscriptionId, type) => {
        return httpClient(`${apiUrl}/backoffice/configuration?${type}=${subscriptionId}`, {
            method: 'GET',
        }).then(({json}) => ({data: json}));
    },

    register: (body) => {
        return httpClient(`${apiUrl}/user`, {
            method: 'POST',
            body: JSON.stringify(body),
        }).then(({json}) => ({data: json}))
            .catch((error) => {
                return Promise.reject({status: error.status, message: error, route: 'register'})
        });
    },

    activateUser: (email) => {
        return httpClient(`${apiUrl}/user/${email}/activate-backoffice`, {
            method: 'PUT',
        }).then(({json}) => ({data: json}))
    },

};
