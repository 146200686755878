import { createMuiTheme } from '@material-ui/core/styles';
require('typeface-titillium-web');

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Titillium Web', 'sans-serif']
    },
    palette: {
        primary: {
            main: '#2E4493',
        },
        secondary: {
            main: '#687B93',
        },
        background: {
            default: '#FFFFFF',
        },
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: 40,
                lineHeight: '48px',
                fontWeight: 'bold',
                letterSpacing: '-1px',
            },
            h2: {
                fontSize: 32,
                lineHeight: '30px',
                fontWeight: 'bold',
                letterSpacing: '-0.2px',
            },
            h3: {
                fontSize: 28,
                lineHeight: '30px',
                fontWeight: 'bold',
            },
            body1: {
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 'normal',
            },
            body2: {
                fontSize: 14,
            }
        },
        MuiCardContent: {
            root: {
                padding: 0,
                "&:last-child": {
                    paddingBottom: 0,
                },
            },
        },
        MuiButton: {
            root: {
                fontWeight: 'bold',
                fontSize: 14,
                textTransform: 'uppercase',
                color: '#225599',
                borderRadius: 4,
                '&:hover': {
                    color: '#225599',
                },
                '&:focus':{
                    color: '#225599',
                },
                '&:active': {
                    color: '#225599',
                },
                '&:disabled': {
                    opacity: 0.5,
                    color: '#225599',
                }
            },
            textPrimary: {
                color: '#225599'
            },
            textSecondary: {
                color: '#225599'
            }
        }
    },
});

export default theme;
