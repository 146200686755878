import {makeStyles, Card, CardContent, Typography, Grid, Divider} from '@material-ui/core/';
import React from "react";
import {useTranslate} from "react-admin";
import SimpleListItem from "./SimpleListItem";
import {getConfiguredSensors} from "../utils/utils";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderSize: 2,
        padding: 15,
    },
    gridContainer: {

    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
}));

const SubscriptionCard = ({subscription}) => {
    const classes = useStyles();
    const translate = useTranslate();

    return(
        <Card className={classes.root}>
            <Typography color="primary" variant="h1">{translate('subscription.card.title')}</Typography>
            <Divider/>
            <Grid container className={classes.gridContainer} spacing={1}>
                <Grid item key="smartband1" xs={12} sm={6} md={6}>
                    <CardContent>
                        <SimpleListItem itemLabel={translate('subscription.card.subscriptionId')} itemValue={subscription.subscriptionId}/>
                        <SimpleListItem itemLabel={translate('subscription.card.multicastAddress')} itemValue={subscription.multicastAddress}/>
                        <SimpleListItem itemLabel={translate('subscription.card.smartbandCount')} itemValue={subscription.smartwatches.length.toString()}/>
                        <SimpleListItem itemLabel={translate('subscription.card.sensorCount')} itemValue={getConfiguredSensors(subscription)}/>
                        <SimpleListItem itemLabel={translate('subscription.card.expire')} itemValue={moment.unix(subscription.expire).format('YYYY/MM/DD')}/>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export default SubscriptionCard
