import React from "react";
import {
    makeStyles,
    Typography,
} from '@material-ui/core/';
import {getItemValue} from '../utils/utils'

const useStyles = makeStyles((theme) => ({
    listItem: {
        lineHeight: 2,
    },
    key: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    }
}))

const SimpleListItem = ({itemLabel, itemValue, type}) => {
    const classes = useStyles();

    return (
        <Typography className={classes.listItem}>
            <span className={classes.key}>{itemLabel}</span>
            {itemValue} {getItemValue(itemValue)}
        </Typography>
    )
}

export default SimpleListItem
