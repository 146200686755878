import React from 'react';
import {Box, CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    },
}))

const LWCircularProgress = () => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <CircularProgress/>
        </Box>
    )
}

export default LWCircularProgress
