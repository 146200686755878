import React from "react";
import {
    makeStyles,
    Box,
    CardContent,
} from '@material-ui/core/';
import ContactCard from "./ContactCard";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 10,
    },
}))

const CollapseContacts = ({contacts}) => {
    const classes = useStyles()
    return (
        <CardContent>
            {contacts.map(contact => (
                <Box key={contact.smartbandId} className={classes.container}>
                    <ContactCard contact={contact}/>
                </Box>
            ))}
        </CardContent>
    )
}

export default CollapseContacts;
