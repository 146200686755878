import sensorIcon00 from './sensorIcons/sensorIcon00.png'
import sensorIcon01 from './sensorIcons/sensorIcon01.png'
import sensorIcon02 from './sensorIcons/sensorIcon02.png'
import sensorIcon03 from './sensorIcons/sensorIcon03.png'
import sensorIcon04 from './sensorIcons/sensorIcon04.png'
import sensorIcon05 from './sensorIcons/sensorIcon05.png'
import sensorIcon06 from './sensorIcons/sensorIcon06.png'
import sensorIcon07 from './sensorIcons/sensorIcon07.png'
import sensorIcon08 from './sensorIcons/sensorIcon08.png'
import sensorIcon09 from './sensorIcons/sensorIcon09.png'
import sensorIcon10 from './sensorIcons/sensorIcon10.png'
import sensorIcon11 from './sensorIcons/sensorIcon11.png'
import sensorIcon12 from './sensorIcons/sensorIcon12.png'
import sensorIcon13 from './sensorIcons/sensorIcon13.png'
import sensorIcon14 from './sensorIcons/sensorIcon14.png'
import sensorIcon15 from './sensorIcons/sensorIcon15.png'
import sensorIcon16 from './sensorIcons/sensorIcon16.png'
import sensorIcon17 from './sensorIcons/sensorIcon17.png'

export default [sensorIcon00, sensorIcon01, sensorIcon02, sensorIcon03, sensorIcon04, sensorIcon05, sensorIcon06, sensorIcon07, sensorIcon08, sensorIcon09, sensorIcon10, sensorIcon11, sensorIcon12, sensorIcon13, sensorIcon14, sensorIcon15, sensorIcon16, sensorIcon17]
