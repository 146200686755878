import React from "react";
import {useTranslate} from "react-admin";
import {makeStyles, Grid, Card, Divider, CardContent, Box, Typography} from '@material-ui/core/';
import ListItem from "./SimpleListItem";

const useStyles = makeStyles((theme) => ({
    card: {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 15,
    },
    cardTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    iconTitle: {
        height: 30,
        paddingRight: 5,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    listTitleLabel: {
        textDecorationLine: 'underline',
    }
}));

const EmergencyCallCard = ({emergencyCall}) => {
    const classes = useStyles()
    const translate = useTranslate()
    const notConfigured = translate('smartband.emergencyCall.notConfigured')

    const NotConfigured = () => {
        return(
            <Card className={classes.card}>
                <CardContent>
                    <Typography>{notConfigured}</Typography>
                </CardContent>
            </Card>
        )
    }

    const EmergencyCall = ({emergencyCall}) => {
        return(
            <Card elevation={4} className={classes.card}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            {emergencyCall && emergencyCall.numbers.map((number,i) => (
                                <ListItem id={i} itemLabel={translate('smartband.emergencyCall.phone',{number: i+1})}
                                          itemValue={number}/>
                            ))}
                            <Divider/>
                            <ListItem itemLabel={translate('smartband.emergencyCall.type')}
                                      itemValue={emergencyCall.type}/>
                            <ListItem itemLabel={translate('smartband.emergencyCall.text')}
                                      itemValue={emergencyCall.text}/>
                            <ListItem itemLabel={translate('smartband.emergencyCall.source')}
                                      itemValue={emergencyCall.source}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
    return (
        <Box>
            {emergencyCall ? <EmergencyCall emergencyCall={emergencyCall}/> : <NotConfigured/>}
        </Box>

    )
}

export default EmergencyCallCard
