import React from "react";
import {useTranslate} from "react-admin";
import {makeStyles, Card, CardContent, Divider, Typography} from '@material-ui/core/';
import smartbandIcons from "../assets/smartbandIcons";
import CollapseSensors from "./CollapseSensors";
import Alarms from "./Alarms";
import CollapsableListItem from "./CollapsableListItem";
import NotificationSettings from "./NotificationSettings";
import UserCard from "./UserCard";
import FirmwareCard from "./FirmwareCard";
import GeneralSettingsCard from "./GeneralSettingsCard";
import CollapseContacts from "./CollapseContacts";
import EmergencyCallCard from "./EmergencyCallCard";
import CollapseScenarios from "./CollapseScenarios";
import DeleteSmartwatchButton from "../Layout/DeleteSmartwatchButton";

const useStyles = makeStyles((theme) => ({
    card: {
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        padding: 15,
    },
    cardTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    iconTitle: {
        marginBottom: 10,
        height: 30,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    listTitleLabel: {
        textDecorationLine: 'underline',
    }
}));

const SmartbandCard = ({smartband}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const firmwareWarning = smartband.firmware && smartband.firmware.currentVersion !== smartband.firmware.serverVersion

    const smartbandStatus = {
        'initialized': 'Smartband non associata',
        'bound': 'Smartband associata',
        'booked': 'Invito in attesa di accettazione'
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardTitle}>
                <img src={smartbandIcons.smartband} alt="smartband" className={classes.iconTitle}/>
                <Typography color="primary" gutterBottom variant="h2">
                    {smartband.uuid || 'Smartband non associata'}
                </Typography>
            </CardContent>
            <CardContent className={classes.cardTitle}>
                <Typography variant="h6" color="primary">
                    {translate('smartband.internalId')} <b>{smartband.id}</b>
                </Typography>
            </CardContent>
            <CardContent className={classes.cardTitle}>
                <Typography color="primary" gutterBottom variant="h6">
                    {translate('smartband.status')} <b>{smartbandStatus[smartband.status]}</b>
                </Typography>
                <DeleteSmartwatchButton smartwatch={smartband}/>
            </CardContent>
            <Divider className={classes.divider}/>
            <GeneralSettingsCard smartband={smartband}/>
            <CollapsableListItem icon={smartbandIcons.user} label={translate('smartband.user.self')}
                                 content={<UserCard user={smartband.user && smartband.user}/>}/>
            <CollapsableListItem icon={smartbandIcons.firmware} label={translate('smartband.firmware.self')}
                                 content={<FirmwareCard firmware={smartband.firmware && smartband.firmware}/>}
                                 warning={firmwareWarning}/>
            <CollapsableListItem icon={smartbandIcons.sensor} label={translate('smartband.sensors')}
                                 content={<CollapseSensors sensors={smartband.sensors}/>}
                                 count={smartband.sensors.length.toString()}/>
            <CollapsableListItem icon={smartbandIcons.alarm} label={translate('smartband.alarms')}
                                 content={<Alarms alarms={smartband.alarms}/>}
                                 count={smartband.alarms.length.toString()}/>
            <CollapsableListItem icon={smartbandIcons.scenarios} label={translate('smartband.scenarios.self')}
                                 content={<CollapseScenarios scenarios={smartband.scenarios && smartband.scenarios}/>}
                                 count={smartband.scenarios.length.toString()}/>
            <CollapsableListItem icon={smartbandIcons.settings} label={translate('smartband.notificationSettings.self')}
                                 content={<NotificationSettings
                                     notificationSettings={smartband.notificationSettings && smartband.notificationSettings}/>}/>
            <CollapsableListItem icon={smartbandIcons.smartband} label={translate('smartband.contacts.self')}
                                 content={<CollapseContacts contacts={smartband.contacts}/>}
                                 count={smartband.contacts.length.toString()}/>
            <CollapsableListItem icon={smartbandIcons.emergencyCall} label={translate('smartband.emergencyCall.self')}
                                 content={<EmergencyCallCard emergencyCall={smartband.emergencyCall}/>}
                                 count={smartband.emergencyCall ? smartband.emergencyCall.numbers.length.toString() : '0'}/>
        </Card>
    )
}

export default SmartbandCard
