import React from 'react';
import {Button} from '@material-ui/core/';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        zIndex: 940,
        height: 35,
        fontWeight: 'bold',
        fontSize: 14,
        textTransform: 'uppercase',
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        '&:hover,&:focus,&:active': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
        '&:disabled': {
            opacity: 0.5,
            color: '#FFFFFF',
        }
    }
}));

const CustomButton = ({...props}) => {
    const classes = useStyles();

    return(
        <Button {...props} className={classes.button}>{props.children}</Button>
    );
}

export default CustomButton;
