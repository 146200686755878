const Eng = {
    switchLanguage : "ITALIANO",
    notifications: {
        genericError: 'Error during login',
        unauthorizedUser: 'Unauthorized user',
    },
    pageNotFoundText: 'Page not found',
    login: {
        form: {
            description: 'Welcome! Enter your email and password to log in.',
            emailInput: 'Email',
            passwordInput: 'Password',
            loginButtonLabel: 'Login',
            rememberMe: 'Remember me',
            register: 'Registration',
            forgotPassword: 'Forgot password?',
        }
    },
    forgotPassword: {
        emailSent: 'Perfect! If your email is present in the system you will receive a link with the recovery instructions',
        resetFailed: 'Something went wrong, please try again or write to servizioclienti@intendime.com',
        resetSuccess: 'Recovery mail sent',
        form: {
            title: 'Password recovery',
            description: 'Enter your email to recover your password.',
            email: 'Email',
            confirm: 'Send',
            back: 'Back',
        }
    },
    recovery: {
        invalidRecoveryLink: 'Sorry, the link is no longer valid',
        resetFailed: 'It was not possible to set your password, please try again or write to servizioclienti@intendime.com',
        resetSuccess: 'Password set correctly, now go back to the app and log in',
        form: {
            title: 'New Password',
            description: 'Set your new password',
            password: 'Password',
            repeatPassword: 'Repeat Password',
            confirm: 'Confirm',
            invalidPassword: 'Password must have at least 8 characters, uppercase, lowercase and numbers',
        }
    },
    registration: {
        registrationSuccess: 'Congratulations, the registration was successful',
        registrationSuccessNote: 'You will soon receive an email with a link to activate your account',
        guestRegistrationSuccessNote: 'Now you can log into the application with the credentials you created',
        registrationSuccessSecondNote: 'By clicking on the link your account will be active on IntendiMe, you can access the app using the email address and password you chose in the previous screen and configure your smartwatches and sensors',
        form: {
            title: 'New Password',
            description: 'Enter your registration data',
            email: 'Email',
            repeatEmail: 'Repeat Email',
            password: 'Password',
            repeatPassword: 'Repeat Password',
            displayName: 'Name',
            subscriptionId: 'Subscription code',
            register: 'Register',
            passwordsNotMatching: 'password do not match',
            emailNotMatching: 'email do not match',
            passwordNotValid: 'Password must have at least 8 characters, uppercase, lowercase and numbers',
            invalidEmail: 'enter a valid email format'
        },
        notifications: {
            success: 'Account create',
            errors: {
                default: 'Errore %{status} - %{message}',
                passwordValidation: 'Passwords do not match',
                invalidPassword: 'Password must have at least 8 characters, uppercase, lowercase and numbers',
                403: 'Error, ID\'s subscription is not valid',
                409: 'Error, this subscription is already registered',
            }
        }
    },
    activate: {
        success: 'Your account has been activated',
        successSubtitle: 'Enter the IntendiMe app and log in with the email address and password you chose during registration to start using the services of IntendiMe',
        error: 'Your account is already active',
        errorSubtitle: 'Log in to the IntendiMe app using the email address and password you chose to start using IntendiMe services',
    },
    subscription: {
        placeholder: 'Utilizza il form di ricerca per visualizzare le configurazioni dell\'abbonamento',
        noSubscription: 'La subscription non esiste',
        card: {
            title: 'Dettagli Abbonamento',
            subscriptionId: 'ID: ',
            multicastAddress: 'Multicast: ',
            smartbandCount: 'Smartband: ',
            sensorCount: 'Sensori configurati: ',
            expire: 'Valido fino al: ',
        },
        users: {
            success: 'Utente eliminato',
            errors: {
                default: 'Errore generico',
                404: 'Operazione fallita, utente non trovato'
            },
            noUsers: 'La smartband deve ancora essere inizializzata',
            button: 'Elimina',
            confirm: {
                title: 'Attenzione',
                content: 'L\'operazione è irreversibile, sei sicuro di voler procedere?',
            },
            card: {
                title: 'Utenti dell\'abbonamento',
                owner: 'Intestatario: ',
                member: 'Membro: ',
            }
        },
    },
    smartband: {
        noSmartbandData: 'Non ci sono smartband associate a questo abbonamento',
        notConfigured: 'Non configurato',
        na: 'n/a',
        uuid: 'UUID: %{uuid}',
        status: 'Stato: ',
        internalId: 'ID Interno: ',
        watchId: 'ID smartband: %{watchId}',
        main: 'principale',
        sensors: 'Sensori',
        alarms: 'Sveglie',
        generalSettings: {
            notifications: 'Stato notifiche',
            vibration: 'Vibrazione',
            luminosity: 'Luminosità LED',
        },
        emergencyCall: {
            self: 'Chiamate di emergenza',
            notConfigured: 'Le chiamate di emergenza non sono ancora state configurate',
            phone: 'Telefono di emergenza %{number}: ',
            type: 'Metodo di contatto preferito: ',
            text: 'Testo SMS: ',
            source: 'Telefono Utente: ',
        },
        firmware: {
            self: 'Firmware',
            notConfigured: 'Questa smartband non è ancora stata configurata',
            currentVersion: 'Versione Corrente: ',
            serverVersion: 'Versione Server: ',
            remoteUrl: 'Url: ',
            status: 'Stato: ',
        },
        user: {
            notConfigured: 'Questa smartband non è ancora stata associata a un utente',
            self: 'Utente',
            name: 'Nome: ',
            userId: 'ID: ',
            email: 'Email: ',
            status: 'Stato: ',
            termsAndCondition: 'T&C: ',
            't&c': {
                accepted: 'Accettati',
                declined: 'Non accettati',
            },
            avatar: 'Avatar: ',
            supportLanguage: 'Lingua Supporto: ',
        },
        notificationSettings: {
            self: 'Impostazioni Smartwatch',
            uniqueColor: 'Colore Unico',
            customizedColors: 'Colori Personalizzati',
            notificationEnabled: 'Abilita LED di notifiche',
            selectedScreen: 'Schermo selezionato: ',
        },
        alarm: {
            name: 'Etichetta: ',
            hour: 'Ora: ',
            status: 'Stato: ',
            days: 'Ripetizioni: ',
        },
        scenarios: {
            self: 'Scenari',
            name: 'Nome: ',
            status: 'Stato: ',
            card: {
                on: 'Tutte le notifiche attive',
                off: 'Tutte le notifiche disattivate'
            }
        },
        contacts: {
            self: 'Contatti',
            tooltips: {
                noConnection: 'Problemi di connessione'
            }
        },
        sensor: {
            statusType: {
                default: 'n/a',
                initialized: 'Questo sensore è ancora da associare',
                bound: 'Questo sensore è stato associato ma non ancora configurato',
                configured: 'Questo sensore è stato configurato'
            },
            errors: {
                default: 'Errore generico',
                404: 'Operazione fallita, sensore non trovato'
            },
            confirm: {
                title: 'Attenzione',
                content: 'Stai per rimuovere il sensore, sei sicuro di voler procedere?',
                success: 'Sensore eliminato',
            },
            uuid: 'UUID: ',
            sensorIndex: 'Indice Sensore: ',
            status: 'Stato: ',
            configuration: {
                name: 'Nome: ',
                icon: 'Icona: ',
                notificationType: 'Tipo notifiche: ',
                usageType: 'Tipo utilizzo: ',
                sensitivity: 'Sensibilità: ',
                soundSpring: 'Fonte sonora: ',
                soundSource: {
                    type0: 'Altro',
                    type1: 'Citofono',
                    type3: 'Porta o Finestra',
                    type4: 'Telefono',
                    type5: 'Allarme',
                    type6: 'Robot da cucina',
                    type7: 'Campanello',
                    type8: 'Asciugatrice',
                    type9: 'Lavatrice',
                    type10: 'BabyMonitor',
                    type11: 'Culla',
                    type12: 'Forno',
                    type13: 'Lavastoviglie',
                    type14: 'Microonde',
                    type15: 'Configurazione avanzata',
                },
                sensitivityType: {
                    label:{
                        ultralow: 'ultra basso',
                        verylow: 'molto basso',
                        low: 'basso',
                        mediumlow: 'medio basso',
                        medium: 'medio',
                        mediumhigh: 'medio alto',
                        high: 'alto',
                        veryhigh: 'molto alto',
                        ultrahigh1: 'super alta 1',
                        ultrahigh2: 'super alta 2',
                        sound: 'suono'
                        }
                }
            },
            firmware: {
                currentVersion: 'Vers. : ',
                serverVersion: 'Server V.: ',
                remoteUrl: 'Url: ',
                status: 'Stato: ',
            },
        },
        form: {
            emailOrSubscription: 'Cerca per email o Codice abbonamento',
            description: 'Inserisci l\'identificativo dell\'abbonamento',
            searchText: 'oppure',
        },
        errors: {
            default: 'Errore generico',
            404: 'Operazione fallita, smartband non trovata'
        },
        confirm: {
            title: 'Attenzione',
            content: 'Stai per rimuovere la smartband, sei sicuro di voler procedere?',
            success: 'Smartband eliminata',
        },
    },
    notFound: {
        text: {
            first: 'Assembramento',
            second: 'nel server',
        },
        button: {
            retry: 'Riprova',
        }
    },
};

module.exports = Eng;