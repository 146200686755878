import {IconButton, Tooltip} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Confirm, useDataProvider, useNotify, useRefresh} from "react-admin";
import React, {useState} from "react";

const ActivateUserButton = ({user}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const refresh = useRefresh()
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleActivateClick = () => setOpen(true);
    const handleActivateDialogClose = () => setOpen(false);

    const handleActivateConfirm = (email) => {
        dataProvider.activateUser(email).then((res) => {
            notify('subscription.users.activate.success')
            console.log(res)
            refresh()
        }).catch((e) => {
            switch(e.status) {
                case 404: return notify('subscription.users.errors.404', 'error')
                default: notify('subscription.users.errors.default', 'error',{status: e.status , message: e.message})
            }
        }).finally(() => {
            setLoading(false)
            setOpen(false)
        })
    };

    return(
        <React.Fragment>
            <Tooltip title="Attiva utente manualmente">
                <IconButton onClick={handleActivateClick}>
                    <CheckCircleIcon/>
                </IconButton>
            </Tooltip>
            <Confirm
                isOpen={open}
                loading={loading}
                title="subscription.users.confirm.title"
                content="subscription.users.confirm.content"
                onConfirm={() => handleActivateConfirm(user.email)}
                onClose={handleActivateDialogClose}
            />
        </React.Fragment>
    )
}

export default ActivateUserButton;
