import React from "react";
import {useTranslate} from "react-admin";
import {makeStyles, Grid, Card, CardContent, Box, Tooltip, Typography} from '@material-ui/core/';
import ListItem from "./SimpleListItem";

const useStyles = makeStyles((theme) => ({
    card: {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 15,
    },
    cardTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    iconTitle: {
        height: 30,
        paddingRight: 5,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    listTitleLabel: {
        textDecorationLine: 'underline',
    }
}));

const UserCard = ({user}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const na = 'n/a';

    const NotConfigured = () => {
        return(
            <Card className={classes.card}>
                <CardContent>
                    <Typography>{translate('smartband.user.notConfigured')}</Typography>
                </CardContent>
            </Card>
        )
    }

    const Configured = ({user}) => {
        return(
            <Card elevation={4} className={classes.card}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6}>
                            <ListItem itemLabel={translate('smartband.user.name')} itemValue={user.name ? user.name : na}/>
                            <ListItem itemLabel={translate('smartband.user.userId')} itemValue={user.userId ? user.userId : na}/>
                            <ListItem itemLabel={translate('smartband.user.email')} itemValue={user.email ? user.email : na}/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <ListItem itemLabel={translate('smartband.user.avatar')} itemValue={user.avatar ? user.avatar : na}/>
                            <ListItem itemLabel={translate('smartband.user.supportLanguage')}
                                      itemValue={user.settings.supportLanguage ? user.settings.supportLanguage : na}/>
                            {/*<ListItem itemLabel={translate('smartband.user.termsAndCondition')}*/}
                            {/*          itemValue={user.settings.termsAndCondition ? translate('smartband.user.t&c.accepted') : translate('smartband.user.t&c.declined')}/>*/}
                            <ListItem itemLabel={translate('smartband.user.status')} itemValue={user.status ? user.status : na} type={'user'}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
    return (
        <Box>
            {user ? <Configured user={user}/> : <NotConfigured user={user}/>}
        </Box>

    )
}

export default UserCard
