import React from "react";
import {useTranslate} from "react-admin";
import {makeStyles, Grid, Card, CardContent, Divider, Box, Typography} from '@material-ui/core/';
import sensorIcons from "../assets/sensorIcons";
import sensorAlert from '../assets/sensorIcons/sensorAlert.png'
import ListItem from "./SimpleListItem";
import DeleteSensorButton from "../Layout/DeleteSensorButton";
import DeleteSmartwatchButton from "../Layout/DeleteSmartwatchButton";

const useStyles = makeStyles((theme) => ({
    card: {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 15,
    },
    cardTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 1,
        marginBottom: 15,
        marginTop: 15,
    },
    iconTitle: {
        height: 30,
        paddingRight: 5,
    },
}));

const SensorCard = ({sensor}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notConfigured = translate('smartband.sensor.notConfigured');

    const notConfiguredPlaceholder = status => {
        switch(status) {
            case 'initialized': return translate('smartband.sensor.statusType.initialized')
            case 'bound': return translate('smartband.sensor.statusType.bound')
            default: return translate('smartband.sensor.statusType.default')
        }
    }

    const NotConfigured = ({sensor}) => {
        return(
            <Card className={classes.card}>
                <CardContent className={classes.cardTitle}>
                    <img src={sensorAlert} alt={'sensorAlert'} className={classes.iconTitle}/>
                    <Typography color="primary" style={{fontWeight: 'bold'}}>{sensor.uuid ? sensor.uuid : 'n/a'}</Typography>
                </CardContent>
                <Divider className={classes.divider}/>
                <CardContent>
                    <Typography>{notConfiguredPlaceholder(sensor.status)}</Typography>
                </CardContent>
            </Card>
        )
    }

    const Configured = ({sensor}) => {

        return(
            <Card elevation={4} className={classes.card}>
                <CardContent className={classes.cardTitle}>
                    {sensor.configuration && <img src={sensorIcons[sensor.configuration.icon]} alt="sensor" className={classes.iconTitle}/>}
                    <DeleteSensorButton sensor={sensor}/>
                    <Typography color="primary" style={{fontWeight: 'bold'}}>{sensor.configuration.name} ({sensor.uuid})</Typography>
                </CardContent>
                <Divider className={classes.divider}/>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6}>
                            <ListItem itemLabel={translate('smartband.sensor.sensorIndex')}
                                      itemValue={sensor.sensorIndex ? sensor.sensorIndex : notConfigured}/>
                            <ListItem itemLabel={translate('smartband.sensor.configuration.notificationType')}
                                      itemValue={sensor.configuration ? sensor.configuration.notificationType : notConfigured}/>
                            <ListItem itemLabel={translate('smartband.sensor.configuration.usageType')}
                                      itemValue={sensor.configuration ? sensor.configuration.usageType : notConfigured}/>
                            <ListItem itemLabel={translate('smartband.sensor.configuration.soundSpring')}
                                      itemValue={sensor.configuration ? translate('smartband.sensor.configuration.soundSource.type' + sensor.configuration.soundSource ) : notConfigured}/>

                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <ListItem itemLabel={translate('smartband.sensor.firmware.currentVersion')}
                                      itemValue={sensor.firmware ? sensor.firmware.currentVersion : notConfigured}/>
                            <ListItem itemLabel={translate('smartband.sensor.firmware.serverVersion')}
                                      itemValue={sensor.firmware ? sensor.firmware.serverVersion : notConfigured}/>
                            <ListItem itemLabel={translate('smartband.sensor.firmware.status')}
                                      itemValue={sensor.status ? sensor.status : notConfigured}/>
                            <ListItem itemLabel={translate('smartband.sensor.configuration.sensitivity')}
                                      itemValue={sensor.configuration.sensitivity ? translate('smartband.sensor.configuration.sensitivityType.label.' + sensor.configuration.sensitivity.label) : notConfigured}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
    return (
        <Box>
            {sensor.configuration ? <Configured sensor={sensor}/> : <NotConfigured sensor={sensor}/>}
        </Box>

    )
}

export default SensorCard
