import React, {useEffect, useState} from 'react';
import {Typography, Grid, Box, FormControlLabel, Checkbox, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {useLogin, useNotify, useTranslate, Notification} from "react-admin";
import CustomButton from "../CustomButton";
import FormWrapper from "./FormWrapper";
import FormInput from "./FormInput";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';
import CustomChangeLanguage from '../CustomChangeLanguage'

const defaultLanguage = () => {
    if (localStorage.getItem('favouriteLanguage')) {
        return engLanguage;
    } return itaLanguage;
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(1, 0, 1),
    },
    input: {
        zIndex: 10,
        height: 40,
    },

}));

const Login = ({theme}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    const [language, setLanguage] = useState(defaultLanguage);
    const login = useLogin();
    const notify = useNotify();
    const submit = (e) => {
        e.preventDefault();
        const username = email
        login({username, password})
            .catch(() => notify(translate('notifications.genericError'), 'info'))
            .finally(() => checked && localStorage.setItem('email', email));
    };

    const handleCheckBoxClick = () => {
        setChecked(!checked);
        if (checked) {
            localStorage.removeItem('email')
        }
    }

    const handleLanguage = () => {
        if (!localStorage.getItem('favouriteLanguage')) {
            setLanguage(engLanguage);
            localStorage.setItem('favouriteLanguage','eng')
        } else {
            setLanguage(itaLanguage);
            localStorage.removeItem('favouriteLanguage')
        }    
    }

    useEffect(() => {
        const localEmail = localStorage.getItem('email')
        if (localEmail) {
            setEmail(localEmail);
            setChecked(true);
        }
    }, [setEmail])

    useEffect(() => {
        if (email && password) {
            setDisabled(false);
        } else (setDisabled(true))
    }, [email, password]);

    return (
        <ThemeProvider theme={theme}>
            <FormWrapper description={language.login.form.description} submit={submit}>   
            <CustomChangeLanguage onClick={handleLanguage}> {language.switchLanguage} </CustomChangeLanguage>     
                <Box>            
                    <FormInput
                        required
                        value={email ? email : ""}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={e => setEmail(e.target.value.toLowerCase())}
                        autoFocus
                        placeholder = {language.login.form.emailInput}
                        className={classes.input}
                    />
                    <FormInput
                        required
                        name="password"
                        placeholder = {language.login.form.passwordInput}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        className={classes.input}
                        onChange={e => setPassword(e.target.value)}
                    />
                </Box>
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" checked={checked}
                                       onClick={handleCheckBoxClick}/>}
                    label={<Typography color="secondary">
                        {language.login.form.rememberMe}
                        </Typography>}
                />
                <Box className={classes.submit}  >
                    <CustomButton
                        disabled={disabled}
                        type="submit"
                        fullWidth  
                    >
                        {language.login.form.loginButtonLabel}
                    </CustomButton>
                </Box>
                <Grid container>
                    <Grid item xs>
                        <Link href="#/forgotPassword" variant="body2">
                            {language.login.form.forgotPassword}
                        </Link>
                    </Grid>
                </Grid>
                <Notification/>
            </FormWrapper>
        </ThemeProvider>
    );
}

export default Login;