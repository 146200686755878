import {IconButton, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {Confirm, useDataProvider, useNotify, useRefresh, useTranslate} from "react-admin";
import {Box} from "../smartband/UsersCard";
import React, {useState} from "react";



const DeleteUserButton = ({user}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const refresh = useRefresh()
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteClick = () => setOpen(true);
    const handleDeleteDialogClose = () => setOpen(false);

    const handleDeleteConfirm = (email) => {
        dataProvider.delete('backoffice/user', {id: email}).then(() => {
            notify('subscription.users.success')
            refresh()
        }).catch((e) => {
            switch(e.status) {
                case 404: return notify('subscription.users.errors.404', 'error')
                default: notify('subscription.users.errors.default', 'error',{status: e.status , message: e.message})
            }
        })
        setOpen(false);
    };

    return(
        <React.Fragment>
            {localStorage.getItem('email').startsWith("admin") &&
            <Tooltip title="Elimina utente dalla subscription">
                <IconButton onClick={handleDeleteClick}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            }
            <Confirm
                isOpen={open}
                loading={loading}
                title="subscription.users.confirm.title"
                content="subscription.users.confirm.content"
                onConfirm={() => handleDeleteConfirm(user.email)}
                onClose={handleDeleteDialogClose}
            />
        </React.Fragment>
    )
}

export default DeleteUserButton;
