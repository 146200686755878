import React, {useEffect, useState} from 'react';
import {useDataProvider, useTranslate, useRefresh} from "react-admin";
import {
    Container,
    Box,
    CircularProgress,
    makeStyles,
    Divider,
    Grid,
    Typography
} from '@material-ui/core/';
import {UserProvider} from "../UserContext";
import {getUser} from "../utils/utils";
import SmartbandCard from "./SmartbandCard";
import CustomTopbar from "../Layout/CustomTopbar";
import SubscriptionCard from "./SubscriptionCard";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import UsersCard from "./UsersCard";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        minHeight: 550,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        width: '100%',
        marginTop: 20,
        position: 'relative',
    },
    gridContainer: {
        flexGrow: 1,
    },
    divider: {
        color: 'red',
        marginBottom: 15,
        marginTop: 15,
    },
    icon: {
        fontSize: 72,
        color: theme.palette.secondary.main
    },
    paper: {
        alignContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
}));

const SmartbandList = (props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);
    const [hasFetched, setHasFetched] = useState();
    // const [landing, setLanding] = useState(true)
    const user = getUser();

    useEffect(() => {
        setLoading(true)
    }, [props.match.params.subscriptionId])

    // useEffect(() => {
    //     if(loading) {
    //         setHasFetched(false)
    //     }
    // }, [loading])

    useEffect(() => {
        if (loading) {
            const subscriptionId = props.match.params.subscriptionId
            if(!subscriptionId) {
                setLoading(false)
                return
            }
            dataProvider.getSmartbandConfig(subscriptionId, 'email').then((res) => {
                setSubscription(res.data.subscription)
                // setLanding(false)
            }).catch(() => {
                dataProvider.getSmartbandConfig(subscriptionId, 'subscriptionId').then((res) => {
                    setSubscription(res.data.subscription)
                    // setLanding(false)
                }).catch(() => {
                    setSubscription(null)
                })
            }).finally(() => {
                setLoading(false);
            });
            setHasFetched(true);
        }
    }, [hasFetched, loading, props.match.params, dataProvider]);

    const Smartband = ({subscription}) => {
        const classes = useStyles();
        const translate = useTranslate();

        return (
            <Container className={classes.root}>
                <Box className={classes.container}>
                    {subscription ?
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}><SubscriptionCard subscription={subscription}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}><UsersCard subscription={subscription}/>
                        </Grid>
                    </Grid>
                        : <CircularProgress/>
                    }
                </Box>
                <Divider className={classes.divider}/>
                <Grid container className={classes.gridContainer} spacing={1}>
                    {subscription ?
                        subscription.smartwatches.length > 0 ? subscription.smartwatches.map(smartband => {
                            return (<Grid item key={smartband.id} xs={12} sm={6} md={6}>
                                <SmartbandCard smartband={smartband}/>
                            </Grid>);
                        }) : <Typography>{translate('smartband.noSmartbandData')}</Typography>
                    : <CircularProgress/>
                    }
                </Grid>
            </Container>
        )
    }

    const Placeholder = () => {
        const translate = useTranslate();

        return (
            <Box>
                <Box className={classes.paper}>
                    <HeadsetMicIcon className={classes.icon}/>
                    <Typography variant="h3" color="secondary">
                        {translate('subscription.placeholder')}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <UserProvider value={user}>
            <CustomTopbar/>
            <Box className={classes.root}>
                {
                        loading ? <CircularProgress/> :
                            subscription ? <Smartband subscription={subscription}/> : <Placeholder/>

                }
            </Box>
        </UserProvider>
    );
}

export default SmartbandList;
