import React from "react";
import {Card, CardContent, makeStyles, Tooltip, Typography,} from '@material-ui/core/';
import {useTranslate} from 'react-admin'
import contactIcon from '../assets/emergencyCallIcon.png'
import noConnectionIcon from '../assets/noConnectionIcon.png'
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    card: {
        borderColor: '#08CAC4',
        borderWidth: 2,
        borderStyle: 'solid',
        padding: '22px 0px 22px 12px',
    },
    defaultContact: {
        backgroundColor: '#08CAC4'
    },
    disabled: {
        opacity: '30%',
    },
    cardContent: {
        display: 'flex',
        flowDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        width: 30,
        height: 30,
    },
    mainText: {
        width: '80%',
        marginLeft: 15,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '22px',
        color: '#17324D'
    }
}))

const ContactCard = ({contact}) => {
    const classes = useStyles()
    const translate = useTranslate()

    const disabled = !contact.active

    return (
        <Card
            className={clsx(classes.card, contact.default && classes.defaultContact, !contact.active && classes.disabled)}
            elevation={0}>
            <Tooltip title={disabled && translate('smartband.contacts.tooltips.noConnection')} disableFocusListener={!disabled} disableTouchListener={!disabled} disableHoverListener={!disabled} >
                <CardContent className={classes.cardContent}>
                    <img src={contactIcon} alt={contactIcon} className={classes.icon}/>
                    <Typography className={classes.mainText}>{contact.name}</Typography>
                    {disabled && <img src={noConnectionIcon} alt={noConnectionIcon} className={classes.icon}/>}
                </CardContent>
            </Tooltip>
        </Card>
    )
}

export default ContactCard;
