import React, {useEffect, useState} from 'react';
import {Typography, Box, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {useNotify, useDataProvider, Notification} from "react-admin";
import CustomButton from "../CustomButton";
import FormWrapper from "./FormWrapper";
import FormInput from "./FormInput";
import {passwordValidator} from "../../utils/fieldValidator";
import theme from "../../theme";
import logo from "../../assets/logo-300x95.png";
import itaLanguage from '../../ita';
import engLanguage from '../../eng';
import CustomChangeLanguage from '../CustomChangeLanguage'

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(1, 0, 1),
    },
    input: {
        zIndex: 10,
        height: 40,
    },
    expiredLinkContainer: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    textContainer: {
        textAlign: 'center'
    }
}));

const defaultLanguage = () => {
    if (localStorage.getItem('favouriteLanguage')) {
        return engLanguage
    } else return itaLanguage;
}

const ExpiredLink = () => {
    const classes = useStyles();

    return (
        <Box className={classes.expiredLinkContainer}>
            <img src={logo} alt={logo}/>
            <Typography variant="h3" className={classes.textContainer} color="primary">
                {defaultLanguage().recovery.invalidRecoveryLink}
            </Typography>
        </Box>
    )
}

const ResetSuccess = () => {
    const classes = useStyles();

    return (
        <Box className={classes.expiredLinkContainer}>
            <img src={logo} alt={logo}/>
            <Typography variant="h3" className={classes.textContainer} color="primary">
                {defaultLanguage().recovery.resetSuccess}
            </Typography>
        </Box>
    )
}

const RecoveryForm = ({setPassword, setRepeatPassword, disabled, submit}) => {
    const classes = useStyles();
    const [language, setLanguage] = useState(defaultLanguage);
    
    const handleLanguage = () => {
        if (!localStorage.getItem('favouriteLanguage')) {
            setLanguage(engLanguage);
            localStorage.setItem('favouriteLanguage',`eng`)
        } else {
            setLanguage(itaLanguage);
            localStorage.removeItem('favouriteLanguage')
        }    
    }

    return (
        <FormWrapper description={defaultLanguage().recovery.form.description} submit={submit}>
            <CustomChangeLanguage onClick={handleLanguage}> {language.switchLanguage} </CustomChangeLanguage>
            <Box>
                <FormInput
                    required
                    id="password"
                    type="password"
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    autoFocus
                    placeholder={defaultLanguage().recovery.form.password}
                    className={classes.input}
                />
                <FormInput
                    required
                    name="newPassword"
                    placeholder={defaultLanguage().recovery.form.repeatPassword}
                    type="password"
                    id="newPassword"
                    className={classes.input}
                    onChange={e => setRepeatPassword(e.target.value)}
                />
            </Box>
            <Box className={classes.buttonContainer}>
                <CustomButton
                    disabled={disabled}
                    type="submit"
                    fullWidth>
                    {defaultLanguage().recovery.form.confirm}
                </CustomButton>
            </Box>
            <Notification/>
        </FormWrapper>
    )
}

const Recovery = (props) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [resetSuccess, setResetSuccess] = useState(false)

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        const userId = props.match.params.userId;
        const code = props.match.params.recoverId;
        if (passwordValidator(password, repeatPassword)) {
            dataProvider.resetPassword({
                password: password,
                code: code,
                userId: userId
            }).then(() => {
                setResetSuccess(true)
            }).catch((e) => {
                return notify('recovery.resetFailed', 'error');
            }).finally(() => {
                setLoading(false);
            });
        } else {
              notify('recovery.form.invalidPassword', 'error');
            }
          setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        if (props.match.params) {
            setIsValid(true);
            setLoading(false);
        }
    }, [dataProvider, props.match.params, notify]);

    useEffect(() => {
        if (repeatPassword === password) {
            setDisabled(false);
        } else (setDisabled(true))
    }, [repeatPassword, password]);

  return (
        <ThemeProvider theme={theme}>
            {
                loading ? <CircularProgress/> :
                    isValid ?
                        resetSuccess ? <ResetSuccess/>:
                            <RecoveryForm password={password} setPassword={setPassword} submit={submit}
                                            setRepeatPassword={setRepeatPassword} disabled={disabled}/> :
                        <ExpiredLink/>
            }
        </ThemeProvider>
    );
}

export default Recovery;
