import React from "react";
import {Card, CardContent, makeStyles, Grid, Switch, Box, Typography,} from '@material-ui/core/';
import {useTranslate} from 'react-admin'
import scenariosIcons from "../assets/scenariosIcons";
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import sensorIcon from "../assets/sensorIcon.png";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    card: {
        borderColor: '#08CAC4',
        borderWidth: 2,
        borderRadius: 12,
        borderStyle: 'solid',
        padding: '22px 0px 22px 12px',
    },
    disabled: {
        border: '2px solid #08CAC4',
    },
    active: {
        backgroundColor: '#08CAC4',
    },
    cardContent: {
        display: 'flex',
        flowDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        width: 30,
        height: 30,
    },
    mainText: {
        width: '80%',
        marginLeft: 15,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '22px',
        color: '#17324D'
    },
    description: {
        fontSize: 14,
        fontWeight: 400
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    subtitleInactive: {
        color: '#000000'
    },
    listItem: {
        paddingLeft: 20,
    },
    resourceListItemLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    resources: {
        paddingTop: 20
    }
}))

const ScenarioCard = ({scenario}) => {
    const classes = useStyles()
    const translate = useTranslate()

    const active = scenario.status === 'active'
    const defaults = ["On", "Off"]

    const CardContentDefaults = (scenario) => {
        return (
            <CardContent className={classes.cardContent}>
                {defaults.includes(scenario.name) ? <img src={scenariosIcons[scenario.name]} alt={scenariosIcons[scenario.name]} className={classes.icon}/> : <InfoIcon className={classes.icon}/>}
                <Box className={classes.textContainer}>
                    <Typography className={classes.mainText}>{scenario.name}</Typography>
                    <Typography className={clsx(classes.mainText, classes.description, !active && classes.subtitleInactive)}>
                        {scenario.name === defaults[1] && translate('smartband.scenarios.card.off')}
                        {scenario.name === defaults[0] && translate('smartband.scenarios.card.on')}
                    </Typography>
                    {!defaults.includes(scenario.name) &&
                    <Box className={classes.resources}>
                    {scenario.resources.map(resource => {
                        return(<ResourcesListItem key={resource.itemId} resource={resource}/>)
                    })}
                    </Box>}
                </Box>
            </CardContent>
        )
    }

    const ResourcesListItem = ({resource}) => {
        return(
            <Box>
                <Grid container className={classes.listItem}>
                    <Grid item xs={8} sm={8} md={8}>
                        <Box className={classes.resourceListItemLeft}>
                            <img style={{width: 20, paddingRight: 10}} src={sensorIcon} alt='sensor'/>
                            <Typography style={{color: '#444547'}}>{resource.label}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <Switch
                            checked={resource.status === 'active'}
                            name="status"
                            color="primary"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Card
            className={clsx(classes.card, active ? classes.active : classes.disabled )}
            elevation={0}>
            <CardContentDefaults {...scenario}/>
        </Card>
    )
}

export default ScenarioCard;
