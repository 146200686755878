import uuid from 'react-native-uuid';
import {isPublicUrl} from "./utils/utils";
import apiUrl from './utils/api.js';

const authProvider = {
    login: ({ username, password }) =>  {
        const deviceId = uuid.v1();
        const request = new Request(apiUrl + '/login', {
            method: 'POST',
            body: JSON.stringify({ username, password, deviceId }),
            headers: new Headers({ 'Content-Type': 'application/json' , 'app-id':'5f842aaebfed6809a9ac160a'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ accessToken, user }) => {
                    if(user.role !== 'bo-user') return Promise.reject()
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('permissions',user.role);
                    localStorage.setItem('email',user.email);
                    return Promise.resolve();
            });
    },
    logout: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('permissions');
        localStorage.removeItem('createdAt');
        localStorage.removeItem('mid');
        return Promise.resolve();
    },
    checkError: async ({status, route}) => {
        if(route === 'register') return Promise.resolve()

        if (status === 401 || status === 403) {
            return Promise.reject();
        }

        return Promise.resolve();
    },
    checkAuth: () => {
        const token = localStorage.getItem('accessToken');
        if(token || isPublicUrl(window.location.hash)) {
            return Promise.resolve();
        }
        return Promise.reject('Non sei autenticato, effettua prima il login');
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');

        if(role) {
            return Promise.resolve(role);
        }

        if(isPublicUrl(window.location.hash)){
            return Promise.resolve();
        }

        return Promise.reject();
    }
};

export default authProvider;
